<template lang="html">
  <div class="main_pane profile">

    <v-card
      elevation="0"
    >
      <v-card-title><h3>Profile</h3></v-card-title>
      <v-row>
        <v-col class="col-1">
          <v-img
            :src="$auth.user.picture"
          ></v-img>
        </v-col>
        <v-col>
          <v-text>{{$auth.user.name}}</v-text>
        </v-col>
      </v-row>


    </v-card>
  </div>
</template>

<script>
export default {
  name: "Profile",
  created() {
    console.log(this.$auth.user)
  },
  
  computed: {

  }
}
</script>

<style lang="scss">
</style>
