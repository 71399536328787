<template lang="html">


    <div class="main_pane home">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title><h3>TuxSuite</h3></v-card-title>
            <v-card-text>TuxSuite, by Linaro, is a set of tools and on demand services.</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title><h3>TuxBuild</h3></v-card-title>
            <v-card-text>TuxBuild is a cloud-native highly concurrent Linux kernel build API by Linaro. Use TuxBuild to quickly and reliably build Linux kernels at scale. TuxBuild is a command-line utility that interacts with a highly available API to dispatch builds. It can be run locally or from a CI system. TuxBuild is cloud-native and scales dynamically with load, meaning you are never waiting in line for your kernels to be built. TuxBuild takes advantage of distributed computing and avoids any single points of failure.</v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col>
          <v-card>
            <v-card-title>TuxTest</v-card-title>
            <v-card-text>

            </v-card-text>
          </v-card>
        </v-col> -->

        <v-col>
          <v-card>
            <v-card-title><h3>TuxPub</h3></v-card-title>
            <v-card-text>TuxPub is the open source Serverless File Server, used by the TuxSuite to globally distribute build artifacts.</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title><h3>TuxMake</h3></v-card-title>
            <v-card-text>TuxMake provides portable and reproducible Linux kernel builds across mutliple toolchains, architectures, kernel configurations, and make targets.</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row></v-row>

    </div>




</template>

<script>
export default {
  name: "Home",

  created() {

  }
}
</script>

<style lang="scss">
.main_pane {
  margin-left: 256px;
  padding: 10px 20px;

}
</style>
