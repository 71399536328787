<template lang="html">
  <div class="">
    <v-row>
      <v-col cols="8">
        <v-card :loading="loading">
          <v-card-title><h3>My Tokens</h3></v-card-title>
          <div class="token-view mt-2">
            <v-row class="pl-4 pr-4" v-for="(token, indx) in tokens" v-bind:key="indx">
              <v-col class="pa-0" >
                <TokenCard :userToken="token" />
              </v-col>
            </v-row>
          </div>

        </v-card>
        <!-- <h2 class="overline">My Tokens</h2>
        <h4 class="mb-4 caption">Tokens that can be used for the <a title="tux docs" href="/docs">Tuxsuite API</a></h4> -->
      </v-col>
      <v-col>
        <v-card>
          <v-card-title><h3>Create a Token</h3></v-card-title>
          <v-card-text>Create a TuxSuite token in order to utilize the CLI or integrate in with your custom build systems.

            <v-row>
              <v-col class="mt-4">
                <v-text-field
                  label="Token name"
                  outlined
                  v-model="tokenCreate.name"
                ></v-text-field>
                <v-btn
                elevation="0"
                @click="createToken()"
                color="black"
                :disabled="tokenCreate.waiting"
                >Create</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>
      <!-- <v-col align="end">
        <v-btn to="tokens/create" class="primary mt-3">Create token</v-btn>
      </v-col> -->
    </v-row>
    <!-- <TokenCard :userToken="token" v-for="(token, indx) in tokens" v-bind:key="indx" /> -->
    <v-snackbar
      v-model="tokenCreate.display"
      top
      timeout="-1"
    >
      <div class="sideways-token"><span>Token</span></div><span class="token-snackbar">{{tokenCreate.value}}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text

          v-bind="attrs"
          @click="tokenCreate.display = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import TokenCard from './TokenCard.vue'
export default {
  name: "TokenMain",
  async created() {
    await this.$store.dispatch('tokens/listMyTokens');
    this.loading = false;
  },
  data() {
    return {
      loading: 'rgba(255,255,255,.3)',
      tokenCreate: {
        name: '',
        display: false,
        value: ''
      }
    }
  },
  computed: {
    tokens() {
      return this.$store.getters['tokens/allTokens']
    }
  },
  methods: {
    async createToken() {
      this.tokenCreate.waiting = true;
      let res = await this.$store.dispatch('tokens/createToken', {
        Policy: {
            tuxtest: {
              permissions: ["*"]
            },
            tuxmake: {
              permissions: ["*"]
            },
            tuxbuild: {
              permissions: ["*"]
            },
            tuxpub: {
              permissions: ["*"]
            }
        },
        PolicyVersion: "11-04-2020",
        FriendlyName: this.tokenCreate.name
      })
      this.tokenCreate.waiting = false;
      if (res.status === 200) {
        this.tokenCreate.value = res.data.Token;
        this.tokenCreate.display = true;
        this.tokenCreate.name = '';
      }
    }
  },
  components: {TokenCard}
}
</script>

<style lang="scss">
.token-snackbar {
  color: black;
  padding: 4px 8px;
  margin-left:28px;
  border-radius: 2px;
  font-family: Courier;
  letter-spacing: 1px;
}
.v-snack__wrapper.theme--dark {
  background-color: white !important;
  border-radius: 6px !important;
}
.sideways-token {
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  width: 30px;
  background: #f7797d;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, rgba(222,222,222,.3), #C6FFDD);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, rgba(222,222,222,.3), #C6FFDD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: inset -4px 0 4px rgba(0,0,0,.04);
  span {
    user-select: none;
    position: absolute;
    color: rgba(40,40,40,1);
    transform: rotateZ(270deg) translateX(-14px) translateY(-4px);

  }
  // transform: rotateZ(270deg);
}
</style>
