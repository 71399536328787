<template>
  <v-app v-if="!$auth.loading" :class="breakpointClass">
    <!-- <v-app-bar
      app
      elevation="3"
      color="rgba(255,255,255,.97)"
      elevate-on-scroll="true"
      :height="$auth.isAuthenticated ? '80' : '160'"
    > -->

    <!-- </v-app-bar> -->
    <Splash v-if="!$auth.isAuthenticated" />
    <v-main v-if="$auth.isAuthenticated && dataLoaded" >
      <v-app-bar
          app
          color="white"
          height="90"
        >
        <v-row class="sign_in_bar" justify="center">
          <v-col align-self="center">
            <img
              src="@/assets/tuxsuite.svg"
              class="logo"
            />

          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="center" class="nav_buttons">
            <v-btn
              elevation="0"
              v-if="$auth.isAuthenticated"
              @click="logout"
              color="black"
            >Log out</v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <NavLeft />
      <v-container fluid>
        <router-view></router-view>
      </v-container>

    </v-main>
  </v-app>

</template>

<script>
import { mdiTwitter, mdiEmail } from '@mdi/js'
// import $axios from '@/plugins/axios.js'
import Splash from './views/Splash.vue'
import NavLeft from './views/NavLeft.vue'
export default {
  name: 'app',
  data() {
    return {
      twitterIcon: mdiTwitter,
      emailIcon: mdiEmail,
      dataLoaded: false
    }
  },
  computed: {
    breakpointClass() {
      return this.$vuetify.breakpoint.name
    }
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    signUp() {
      this.$auth.loginWithRedirect({ screen_hint: 'signup' });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  },

  async created() {
    await this.$store.dispatch('retrieveTokenFromAuthz')
    await this.$store.dispatch('users/updateUser', this.$auth.user)
    this.dataLoaded = true;
  },
  components: {Splash,NavLeft}
}
</script>


<style lang="scss">
.logo {
  height: 60px;
}

</style>
