// import $axios from '@/plugins/axios.js'

const state = () => ({
  user: {},
  signinStatus: null
})

const getters = {

}

const actions = {
  async getUser(context, payload) {
    console.log(context)
    console.log(payload)
    // let userInfo = await $axios.get('/api/v1/users/me')
    // context.commit("setUser", userInfo.data);
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
